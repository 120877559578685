<template>
  <VDialog
    :value="value"
    :width="width"
    @input="$emit('input', $event)"
  >
    <VCard class="pa-3">
      <div class="d-flex align-center mb-2">
        <span
          v-if="title"
          class="tt-text-subtitle"
        >{{ title }}</span>
        <VSpacer />
        <VBtn
          icon
          @click="$emit('input', false)"
        >
          <VIcon>fal fa-times</VIcon>
        </VBtn>
      </div>

      <div
        class="tt-text-body-2"
      >
        {{ text }}
      </div>

      <div class="d-flex mt-3">
        <VSpacer />

        <TTBtn
          color="tt-light-mono-8"
          large
          @click="$emit('input', false)"
        >
          {{ cancelText }}
        </TTBtn>

        <TTBtn
          class="ml-4"
          color="tt-light-red"
          dark
          large
          :loading="loading"
          @click="handlerSubmit"
        >
          {{ submitText }}
        </TTBtn>
      </div>
    </VCard>
  </VDialog>
</template>

<script>
export default {
  name: 'ConfirmDelete',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 536,
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      required: true,
    },
    cancelText: {
      type: String,
      default: 'Нет',
    },
    submitText: {
      type: String,
      default: 'Да',
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  watch: {
    value() {
      this.loading = false;
    },
  },
  methods: {
    handlerSubmit() {
      this.loading = true;
      this.$emit('submit', true);
    },
  },
};
</script>
